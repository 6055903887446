import React, { useState } from 'react'
import commonStyles from 'src/styles/commonStyles'
import LogoPng from 'src/components/Atoms/Icons/LogoPng'
import SignInForm from 'src/components/Views/SignIn/SignInForm'
import RecoverPasswordForm from 'src/components/Views/SignIn/RecoverPasswordForm'

export type State = {
  viewVariant: 'signIn' | 'recoverPassword' | 'signUp'
}

const SignIn = () => {
  const commonClasses = commonStyles()

  const [state, setState] = useState<State>({
    viewVariant: 'signIn'
  })

  const handleViewVariantChange = (viewVariant: State['viewVariant']) => {
    setState({ ...state, ['viewVariant']: viewVariant })
  }

  return (
    <>
      <div className={commonClasses.smallContainer}>
        <div className={commonClasses.alignCenter}>
          <LogoPng fullWidth />
        </div>

        {state.viewVariant === 'signIn' && (
          <SignInForm onViewVariantChange={handleViewVariantChange} />
        )}
        {state.viewVariant === 'recoverPassword' && (
          <RecoverPasswordForm onViewVariantChange={handleViewVariantChange} />
        )}
      </div>
    </>
  )
}

export default SignIn

import React, { useContext, useState } from 'react'
import { Button, Grid, Link, TextField, Typography } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { FirebaseContext } from 'src/utils/firebase'
import commonStyles from 'src/styles/commonStyles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import getFirebaseErrorMessage from 'src/utils/firebase/types/AuthError'
import { navigate } from 'gatsby'
import { State as SignInState } from './index'

type UserLogin = {
  email: string
  password: string
}

type Props = {
  onViewVariantChange: (viewVariant: SignInState['viewVariant']) => void
}

/**
 * @constructor
 */
export default function SignInForm({ onViewVariantChange }: Props) {
  // get the variables we need for authentication.
  const { authToken, signInWithEmailAndPassword } = useContext(FirebaseContext)

  // setup some state variables for login
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [signInError, setSignInError] = useState<string>('')

  const classes = useStyles()
  const commonClasses = commonStyles()
  const [t] = useTranslation()

  const { register, handleSubmit, errors } = useForm<UserLogin>()

  const onSubmit = async (data: UserLogin) => {
    // We empty the error message
    setSignInError('')

    console.log('SignInForm.onSubmit')

    try {
      const user = await signInWithEmailAndPassword(data.email, data.password)
      user ? setSignInError('') : setSignInError('error.auth.permissionDenied')
    } catch (e) {
      console.log(e.code)
      setSignInError(getFirebaseErrorMessage(e.code))
    }
  }

  // Effect that will reroute the user to the index.tsx file if there is an user
  React.useEffect(() => {
    if (authToken) {
      console.log('Login is OK, I redirect to home page / ')
      navigate('/')
    }
  }, [authToken])

  return (
    <>
      <div className={classes.withMarginTop}>
        <Typography variant="subtitle1" style={{ fontSize: 27 }}>
          {t('auth.login')}
        </Typography>
      </div>

      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        className={classes.withMarginTop}>
        <InputLabel shrink htmlFor="email" className={commonClasses.bigLabel}>
          {t('auth.email')}
        </InputLabel>
        <TextField
          required
          fullWidth
          error={!!errors.email}
          id="email"
          label={null}
          name="email"
          autoComplete="email"
          value={email}
          helperText={errors.email?.message}
          onChange={(e) => setEmail(e.target.value)}
          inputRef={register({
            required: String(t('error.required')),
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t('error.email')
            }
          })}
          autoFocus
        />

        <InputLabel shrink htmlFor="password" className={commonClasses.bigLabel}>
          {t('auth.password')}
        </InputLabel>
        <TextField
          required
          fullWidth
          name="password"
          error={!!errors.password}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          ref={register}
          label={null}
          inputRef={register({
            required: String(t('error.required'))
          })}
          helperText={errors.password?.message}
          type="password"
          id="password"
          autoComplete="current-password"
        />

        <div className={classes.submitContainer}>
          <Button type="submit" variant="contained" color="primary" className={classes.submit}>
            {t('auth.signIn')}
          </Button>
        </div>
        <Grid container>
          <Grid item xs>
            <Link
              variant="body2"
              target={'_blank'}
              href="https://www.yodo.ch/inscription-commercant/"
              rel="noopener">
              Ouvrir un compte
            </Link>
          </Grid>
          <Grid item xs style={{ textAlign: 'right' }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                onViewVariantChange('recoverPassword')
              }}>
              Mot de passe oublié?
            </Link>
          </Grid>
        </Grid>

        {signInError && <Alert severity="error">{t(signInError)}</Alert>}
      </form>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  withMarginTop: {
    marginTop: theme.spacing(5)
  }
}))

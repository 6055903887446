import { FirebaseError as FBError } from 'firebase'
export type AuthError = FBError & Error

const USER_NOT_FOUND = 'error.auth.firebase.userNotFound'
const EMAIL_ALREADY_EXIST = 'error.auth.firebase.emailAlreadyExists'
const INVALID_EMAIL_FORMAT = 'error.auth.firebase.invalidEmail'
const INVALID_PASSWORD_FORMAT = 'error.auth.firebase.invalidPassword'
const PASSWORD_WEAK = 'error.auth.firebase.weakPassword'
const DEFAULT_MESSAGE = 'error.auth.firebase.defaultMessage'
const CONDITIONS_ACCEPTED = 'error.auth.firebase.conditionsNotAccepted'
const WRONG_PASSWORD = 'error.auth.firebase.wrongPassword'

export default function getFirebaseErrorMessage(code: string) {
  let message
  switch (code) {
    case 'conditions':
      message = CONDITIONS_ACCEPTED
      break
    case 'auth/user-not-found':
      message = USER_NOT_FOUND
      break
    case 'auth/email-already-exists':
    case 'auth/email-already-in-use':
    case 'auth/account-exists-with-different-credential':
      message = EMAIL_ALREADY_EXIST
      break
    case 'auth/wrong-password':
      message = WRONG_PASSWORD
      break
    case 'auth/invalid-email':
      message = INVALID_EMAIL_FORMAT
      break
    case 'auth/invalid-password':
      message = INVALID_PASSWORD_FORMAT
      break
    case 'auth/weak-password':
      message = PASSWORD_WEAK
      break
    default:
      message = DEFAULT_MESSAGE
      break
  }
  return message
}

import React, { ReactElement } from 'react'
import Layout from 'src/components/Layout'
import Page from 'src/components/Page'
import SignIn from 'src/components/Views/SignIn'

export default function LoginPage(): ReactElement {
  return (
    <Layout>
      <Page>
        <SignIn />
      </Page>
    </Layout>
  )
}
